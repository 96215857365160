<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <holiday-select></holiday-select>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import HolidaySelect from './modules/HolidaySelect.vue'

export default {
  components: {
     HolidaySelect
  },
  data() {
    return {
    }
  },
  methods: {
    
  }
}
</script>
